<template>
  <div id="resident-detail-view-informations" class="data-list-container">

    <resident-menu
        :selected="selected"
        :resident="getResident()"
    />
      <div>
        <div class="title">Informations</div>
          <div class="under-content">
            <div class="block_element flex mt-2">
              <div class="block_inside left">
                  <div class="info-title">
                      <span data-cy="resident-text">{{ getResidentNameLabel() }}</span>
                      <a
                          v-if="$aclChecker.some($acl, ['isEhpadManager', 'isTutor', 'isFamily', 'isHappytalEmployee', 'isHappytalSupport', 'isEhpadEmployee'])"
                          class="link update-link"
                          @click.prevent="onUpdateBtnClick"
                          data-cy="update-btn"
                          href=""
                      >Modifier</a>
                  </div>
                  <div class="info-text">Chambre : {{ getResidentRoomLabel() }}</div>
              </div>
              <div class="block_inside right">
                  <div class="info-title">{{ getInstitutionNameLabel() }}</div>
                  <div class="info-text">{{ getInstitutionAddressLabel() }}</div>
                  <div class="info-text" v-html="getResidentInfosHtml()"></div>
              </div>
            </div>
            <div
                class="block_element"
                v-if="$acl.check('isEhpadManager') || $acl.check('isHappytalSupport')"
            >
                <vs-alert
                    icon-pack="feather"
                    class="h-full my-4 small pa-4"
                    color="warning"
                    v-if="$acl.check('isEhpadManager')"
                >
                    <h4 class="titlex vs-alert--title">Vous pouvez désigner un tuteur ou un proche comme référent du résident</h4><br />
                    <div class="info-text">
                        Si vous ne trouvez pas le nom du tuteur ou du proche dans la liste,
                        vous pouvez lui créer un compte directement dans “Référent du résident”.
                    </div>
                </vs-alert>
                <!-- <vs-alert
                    icon-pack="feather"
                    class="h-full my-4 small"
                    color="warning"
                    v-if="$acl.check('isTutor') || $acl.check('isFamily') || $acl.check('isHappytalSupport')"
                >
                    <h4 class="titlex vs-alert--title">Vous êtes désigné comme référent du résident</h4><br />
                    <div class="info-text">
                        Vous pouvez créer et modifier l'abonnement du résident ainsi que ses prochaines commandes.
                    </div>
                </vs-alert> -->
                <div v-if="$acl.check('isEhpadManager') || $acl.check('isHappytalSupport')">
                    <div class="section" >Référent du résident</div>
                    <p class="italic small mb-1">Sélectionner un tuteur ou un proche comme référent du résident</p>

                    <tutor-selector
                        :disabled="$aclChecker.notEvery($acl, ['isEhpadManager', 'isHappytalSupport'])"
                        :selected="tutorSelected"
                        data-cy="tutor-selector"
                        v-on:change="onTutorChange"
                    />
                    <vs-button
                        :disabled="$acl.not.check('isEhpadManager') && $acl.not.check('isHappytalSupport')"
                        class="mr-3 save mt-5"
                        data-cy="tutor-save-btn"
                        @click="onSaveTutorBtnClick"
                    >Enregistrer</vs-button>
                </div>
            </div>
            <div class="block_element last">
              <h2 class="section">Informations particulières</h2>
              <p class="italic small mb-1">
                  Vous pouvez ajouter des informations concernant les besoins du résident en termes de commandes.
                  Les informations ne doivent pas faire apparaître des informations relatives à la santé des résidents.
                  <span v-if="$acl.check('isEhpadManager')">Les informations sont visibles par le référent du résident.</span>
                  <span v-if="$acl.check('isTutor') || $acl.check('isFamily') || $acl.check('isHappytalSupport')">Les informations sont visibles par l'équipe de l’Ehpad.</span>
              </p>
              <h5 class="mt-5">Commentaire</h5>
              <vs-textarea
                  v-model="comment"
                  class="mt-2 pa-2"
                  data-cy="comment-field"
                  @change="onCommentChange"
                  placeholder="Pointure, taille de vêtement, code de coloration..."
              />
              <vs-button
                  class="mt-4 mr-3 save"
                  data-cy="comment-save-btn"
                  @click="onSaveCommentBtnClick"
              >Enregistrer</vs-button>
            </div>
          </div>
          <SpeedDialRefund
            v-if="$_canUserViewPromotional && !!$_residentPromotionalElligibleType"
            :promotional-type="$_residentPromotionalElligibleType"
          />
      </div>

  </div>
</template>

<script>
import moment from 'moment'
import async from 'async'

import Forms from '@/constants/forms'
import Routes from '@/constants/routes'

import residentPromotional from '@/mixins/residentPromotional'

import TutorSelector from '@/components/selectors/TutorSelector'
import ResidentMenu from './ResidentMenu.vue'
import SpeedDialRefund from '@/views/residents/components/SpeedDialRefund.vue'
import Oauth from '@/oauth'

export default {
    mixins: [residentPromotional],

    props: [
        'resident_id'
    ],
    components: {
        TutorSelector,
        ResidentMenu,
        SpeedDialRefund
    },
    data () {
        return {
            resident: null,
            comment: '',
            selected: Routes.ResidentInformations,
            tutorId: null,
            tutorIdBackup: null,
            tutorSelected: null,
            loaded: false,
            saved: true
        }
    },
    methods: {
        getUserId () {
            return this.$store.getters['dataUser/getUserId']()
        },
        getInstitutionId () {
            return this.$store.getters['dataUser/getInstitutionId']()
        },
        getResidentById (residentId) {
            return this.$store.getters['dataResidents/getResidentById'](residentId)
        },
        getInstitutionById (institutionId) {
            return this.$store.getters['dataInstitutions/getInstitutionById'](institutionId)
        },
        getFormById (formId) {
            return this.$store.getters['dataForms/getFormById'](formId)
        },
        getResidentInstitution () {
            const resident = this.getResidentById(this.resident_id)
            if (!resident) {
                return null
            }
            return this.getInstitutionById(resident.institutionId)
        },
        getResidentNameLabel () {
            const resident = this.getResidentById(this.resident_id)
            if (!resident) {
                return '-'
            }
            return this.$store.getters['dataResidents/getResidentName'](resident.id)
        },
        getResident () {
          const resident = this.getResidentById(this.resident_id)
          return resident
        },
        getInstitutionNameLabel () {
            const institution = this.getResidentInstitution()
            if (!institution) {
                return '-'
            }
            return institution.name
        },
        getInstitutionAddressLabel () {
            const institution = this.getResidentInstitution()
            if (!institution) {
                return '-'
            }
            const address = _.get(institution, 'address.address1', '-')
            const zipcode = _.get(institution, 'address.zipPostalCode', '-')
            const city = _.get(institution, 'address.city', '-')
            return `${address}, ${zipcode} ${city}`
        },
        getResidentInfosHtml () {
            const resident = this.getResidentById(this.resident_id)
            const institution = resident ? this.getInstitutionById(resident.institutionId) : null
            const institutionPhone = _.get(institution, 'address.phoneNumber', null)
            const institutionEmail = _.get(institution, 'address.email', null)
            const residentPhone = _.get(resident, 'contactPhoneNumber', null)
            const residentEmail = _.get(resident, 'contactEmail', null)
            const phone = residentPhone || institutionPhone
            const email = residentEmail || institutionEmail
            return `<a class="nolink" href="tel:${phone}" target="_blank">${phone}</a> - <a class="nolink" href="mailto:${email}" target="_blank">${email}</a>`
        },
        getResidentRoomLabel () {
            const resident = this.getResidentById(this.resident_id)
            if (!resident) {
                return '-'
            }
            return resident.roomNumber
        },
        onUpdateBtnClick (e) {
            this.$router.push({
                name: Routes.UpdateResident,
                params: {
                    resident_id: this.resident_id
                }
            })
        },
        onTutorChange (tutorId) {
            if (tutorId == 'create') {
                this.saved = true
                this.$router.push({
                    name: Routes.NewReferant,
                    resident_id: this.resident_id
                })
                return
            }
            /*if (tutorId && this.tutorId && this.tutorId != tutorId) {
                this.saved = false
            }
            if (!tutorId && this.tutorId) {
                this.saved = false
            }*/
            this.tutorId = tutorId
            this.saved = this.tutorIdBackup == this.tutorId
        },
        onSaveTutorBtnClick (e) {
            this.$vs.dialog({
                type: 'confirm',
                color: 'primary',
                title: `Confirmation`,
                text: `Etes vous sûr de vouloir confirmer votre choix ?`,
                acceptText: `Oui`,
                cancelText: `Non`,
                accept: this.attachTutor
            })
        },
        attachTutor () {
            const resident = this.getResidentById(this.resident_id)
            if (!resident) {
                return
            }
            this.$store.dispatch('dataResidents/updateTutor', {
                residentId: resident.id,
                tutor: this.tutorId ? {
                    id: this.tutorId
                } : null
            }).then(() => {
                this.saved = true
                this.deleteReferantForm()
                const message = this.tutorId ?
                `Le tuteur a bien été rattaché.` :
                `Le tuteur a bien été supprimé.`
                this.showInfoToast(message)
            },(error) => {
                this.showErrorToast(`Une erreur s'est produite (${error}).`)
            })
        },
        onCommentChange (value) {
            this.saved = false
        },
        onSaveCommentBtnClick (e) {
            const storeResident = this.getResidentById(this.resident_id)
            this.$store.dispatch('dataResidents/updateResident', {
                resident: {
                    ...storeResident,
                    comment: this.comment
                }
            }).then(() => {
                this.saved = true
                this.showInfoToast(`Le résident a bien été modifié.`)
            },(error) => {
                this.showErrorToast(`Une erreur s'est produite (${error}).`)
            })
        },
        showInfoToast (text) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration : 5000,
                action : [
                    {
                        text : 'Fermer',
                        onClick : (e, to) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        },
        deleteReferantForm () {
            this.$store.commit('dataForms/DELETE_FORM', {
                formId: Forms.NewReferant
            })
        },
        showErrorToast (text) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration : null,
                action : [
                    {
                        text : 'Fermer',
                        onClick : (e, to) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        }
    },
    async mounted () {
        this.loaded = false
        this.saved = true
        async.series([
                (callback) => {
                    if (!this.$store.state.dataInstitutions.isRegistered) {
                        this.$store.dispatch('dataInstitutions/getInstitutions').then(() => {
                            this.$store.state.dataInstitutions.isRegistered = true
                            callback(null)
                        })
                        return
                    }
                    callback(null)
                },
                (callback) => {
                    if (!this.$store.state.dataResidents.isRegistered) {
                        this.$store.dispatch('dataResidents/getResidents', {
                            userId: this.getUserId(),
                            institutionId: this.getInstitutionId()
                        }).then(() => {
                            this.$store.state.dataResidents.isRegistered = true
                            callback(null)
                        })
                        return
                    }
                    callback(null)
                },
                (callback) => {
                    if (!this.$store.state.dataTutors.isRegistered) {
                        this.$store.dispatch('dataTutors/getTutors', {
                            institutionId: this.getInstitutionId()
                        }).then(() => {
                            this.$store.state.dataTutors.isRegistered = true
                            callback(null)
                        })
                        return
                    }
                    callback(null)
                }
            ],
            (err, results) => {
                const resident = this.getResidentById(this.resident_id)
                if (resident) {
                    this.comment = resident.comment
                    const tutor = resident.userInChargeOfResident
                    if (tutor) {
                        this.tutorSelected = tutor.id
                    }
                }
                const formData = this.getFormById(Forms.NewReferant)
                if (formData) {
                    this.tutorSelected = formData.id
                    this.saved = false
                }
                this.tutorIdBackup = this.tutorSelected
                Oauth.checkUserRight(this.$store.getters['dataResidents/getResidentById'](this.resident_id), this.$router)
                this.loaded = true
            }
        )
    },
    beforeRouteLeave (to, from, next) {
        if (this.saved) {
            next()
            return
        }
        this.$vs.dialog({
            type: 'confirm',
            color: 'primary',
            title: `Annuler les modifications`,
            text: `Êtes-vous sûr de vouloir quitter cette page sans enregistrer ? Toutes les données renseignées seront perdues.`,
            acceptText: `Oui`,
            cancelText: `Non`,
            accept: () => {
                this.deleteReferantForm()
                next()
            },
            cancel: () => {
                next(false)
            }
        })
    }
}
</script>

<style lang="scss">
#resident-detail-view-informations {
    .title {
        margin: 0px 0px 17px 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #2C2C2C;
    }
    .update-link {
        margin: 0px 0px 0px 14px;
    }

.under-content {
  background: #FFFFFF;
  padding: 20px 0 20px 0;
  margin: 0 0 30px 0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
  border-radius: 8px;
  font-size: 14px;
  color: #2C2C2C;

  .block_element {
    border-bottom: 1px solid #D8D8D8;

    .section {
        margin: 19px 0px 8px 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
    }
    &.last {
      border-bottom: none;
    }
    padding: 0 20px 20px 20px;
   &.flex {
     display: flex;
   }
    .block_inside {
      width: 50%;

        .info-title {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
        }
        .info-text {
            margin: 8px 0px 0px 0px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
        }
    }
    .strong {
      font-weight: bold;
    }
    .left {
      text-align: left;
    }
    .right {
      text-align: right;
    }
    p.small {
      font-size: 12px;
    }
    .m-r-10 {
      margin-right: 10px;
    }
    .con-select {
      width: 99%;
      display: block;
    }
    .vs-con-textarea {
      width: 99%;
    }
  }
  .save {
    background-color: rgba(var(--vs-primary), 1);
    margin-left: auto !important;
    display: block !important;
  }
}
  .mt-5 {
    margin-top: 1.5rem !important;
  }
  .small {
    font-size: 12px;
    font-weight: normal;
  }
}
</style>
