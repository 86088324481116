<template>
    <div class="resident-menu">
        <div class="items">
            <ul>
                <li v-bind:class="{ selected: selected === 'resident-informations' }">
                    <div class="menu-item">
                        <div class="menu-icon user"></div>
                        <div id="menu-informationResident" class="menu-text">
                            <a v-on:click="clickMenu('resident-informations')">Informations</a>
                        </div>
                    </div>
                </li>
                <li v-bind:class="{ selected: selected === 'resident-catalog' }">
                    <div class="menu-item">
                        <div  class="menu-icon store"></div>
                        <div id="menu-store" class="menu-text">
                            <a v-on:click="clickMenu('resident-catalog')">Catalogue</a>
                        </div>
                    </div>
                </li>
                <li v-bind:class="{ selected: isTabEnabled() }">
                    <div class="menu-item">
                        <div class="menu-icon subscriptions"></div>
                        <div id="menu-subscriptions" class="menu-text">
                            <a @click="clickTab('subscriptions')">Abonnements</a>
                        </div>
                    </div>
                </li>
                <li v-bind:class="{ selected: selected === 'resident-history' }">
                    <div class="menu-item">
                        <div class="menu-icon orders"></div>
                        <div id="menu-history" class="menu-text">
                            <a v-on:click="clickMenu('resident-history')">Commandes</a>
                        </div>
                    </div>
                </li>
                <li v-bind:class="{ selected: selected === 'resident-payment' }">
                    <div class="menu-item">
                        <div class="menu-icon euro"></div>
                        <div id="menu-payment" class="menu-text">
                            <a v-on:click="clickMenu('resident-payment')">Moyen de paiement</a>
                        </div>
                    </div>
                </li>
                <li v-bind:class="{ selected: selected === 'resident-suggestions' }">
                    <div class="menu-item">
                        <div class="menu-icon suggestions"></div>
                        <div id="menu-payment" class="menu-text">
                            <a v-on:click="clickMenu('resident-suggestions')" class="d-flex align-center">Suggestions <span class="menu-chip ml-2" v-if="resident && resident.openedSuggestionCount">{{ resident.openedSuggestionCount }}</span></a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="tabs" v-if="isTabEnabled()">
            <div :class="[ 'tab-item', selected === 'resident-pcp' ? 'tab-item-selected' : '' ]">
                <div id="menu-subscription-recap" class="tab-text">
                    <a v-on:click="clickMenu('resident-pcp')">Récapitulatif</a>
                </div>
            </div>
            <div :class="[ 'tab-item', selected === 'resident-orders' ? 'tab-item-selected' : '' ]">
                <div id="menu-subscription-nextorder" class="tab-text">
                    <a v-on:click="clickMenu('resident-orders')">Prochaine commande - livrée en {{nextMonth}}</a>
                </div>
            </div>
        </div>
        <v-tour
            name="menuTour"
            :steps="steps"
            :options="tourOptions"
            :callbacks="tourCallbacks"
        />
        <v-tour
            name="menuTabsTour"
            :steps=" stepsTab"
            :options="tourOptions"
            :callbacks="tourTabCallbacks"
        />
    </div>
</template>

<script>
    import async from 'async'
    import { getNextMonth } from '@/modules/date'
    export default {
        components: {
        },
        props: {
            selected: {
                type: String,
                required: true
            },
            resident: {
                type: Object
            }
        },
        data () {
            return {
                tabsEnabled: false,
                tourTabCallbacks: {
                    onStop: () => {
                        this.$store.dispatch('dataUser/updateTourStatus',{menuTabs:true})
                    }
                },
                 tourCallbacks: {
                    onStop: () => {
                        this.$store.dispatch('dataUser/updateTourStatus',{menu:true})
                    }
                },
                tourOptions : {
                    labels: {
                        buttonSkip: 'Passer',
                        buttonPrevious: 'Précédent',
                        buttonNext: 'Suivant',
                        buttonStop: 'Terminer'
                    },
                },
                steps: [
                {
                    target: '#menu-store',
                    content: "Faites plaisir au résident grâce a un catalogue de produits et de services pensé spécialement pour le bien-être des seniors.<br/><br/> Vous pourrez lui faire tout de suite plaisir, ou automatiser des commandes grâce à notre fonctionnalité d'abonnement !",
                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    }
                },
                {
                    target: '#menu-subscriptions',
                    content: "Si vous avez opté pour des produits et services en abonnement, c'est ici que vous pourrez à tout moment les consulter et les modifier !",
                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    }
                },
                {
                    target: '#menu-history',
                    content: "Suivez ici le statut de vos commandes et récupérez vos factures si nécessaire",
                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    }
                },
                {
                    target: '#menu-payment',
                    content: "Quand vous souscrivez à un abonnement, nous avons besoin d'enregistrer votre moyen de paiement afin de pouvoir procéder au paiement automatique des commandes mensuelles. <br/><br/> C'est ici que vous pourrez consulter et modifier votre moyen de paiement enregistré !",
                    params: {
                        placement: 'bottom',
                        enableScrolling: false
                    }
                },
            ],
            stepsTab: [
                    {
                        target: '#menu-subscription-recap',
                        content: "C'est ici que vous pourrez consulter et modifier vos abonnements. <br/><br/> Il n'y a aucun engagement, vous pouvez annuler vos abonnements à tout moment !",
                        params: {
                            placement: 'bottom'
                        }
                    },
                    {
                        target: '#menu-subscription-nextorder',
                        content: "Retrouvez ici la commande mensuelle basée sur vos abonnements, livrée le mois suivant. <br/><br/>Vous pourrez la modifier jusqu'au dernier jour du mois en cours.",
                        params: {
                            placement: 'bottom'
                        }
                    },
                ]
            }
        },
        computed: {
            nextMonth () {
              return getNextMonth()
            },
        },
        methods: {
            isTabEnabled () {
                return this.selected === 'resident-pcp' || this.selected === 'resident-orders'
            },
            goTo (action) {
              if (action === 'resident-catalog') {
                this.$router.push({
                  name: action,
                  params: {
                    resident_id: this.$route.params.resident_id,
                    type: 'product'
                  }
                })
              }
              else {
                this.$router.push({
                    name: action,
                    params: {
                        resident_id: this.$route.params.resident_id
                    }
                })
              }
            },
            clickMenu (action) {
                this.goTo(action)
            },
            clickTab (action) {
                switch (action) {
                    case 'subscriptions':
                        this.goTo('resident-pcp')
                        break
                }
            },
            getResidentById (residentId) {
                return this.$store.getters['dataResidents/getResidentById'](residentId)
            },
            getUserId () {
                return this.$store.getters['dataUser/getUserId']()
            }
        },
         mounted () {

            const user = _.get(this.$store, 'state.dataUser.user', {})
            if (this.isTabEnabled()) {
                const menuTabsTour = _.get(this.$store, 'state.dataUser.user.tourStatus.menuTabs', {})
                setTimeout(() => {
                    if (user.firstVisit && menuTabsTour != true)
                        this.$tours['menuTabsTour'].start()
                    },1000)
            }
            else {
                const menuTour = _.get(this.$store, 'state.dataUser.user.tourStatus.menu', {})
                // console.log(menuTour)
                // console.log(user.firstVisit)
                setTimeout(() => {
                    if (user.firstVisit && menuTour !=true)
                        this.$tours['menuTour'].start()
                    },1000)
            }
        }
    }
</script>

<style lang="scss" scoped>
.resident-menu {
    margin: 0 0 30px 0;

    .items {
        position: relative;
        padding: .8rem 1rem;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
        background: rgb(255, 255, 255);
        border-radius: 8px;

        .menu-item {
            display: flex;

            .menu-icon {
                width: 22px;
                height: 22px;
                margin: 0px 10px 0px 0px;
                background-size: 22px 22px;
            }
            .menu-text {
                cursor: pointer;
            }
            .menu-icon.user {
                background-image: url('../../assets/images/ehpad/icon-user.svg');
            }
            .menu-icon.store {
                background-image: url('../../assets/images/ehpad/icon-store.svg');
            }
            .menu-icon.recurence {
                background-image: url('../../assets/images/ehpad/icon-recurence.svg');
            }
            .menu-icon.orders {
                background-image: url('../../assets/images/ehpad/icon-orders.svg');
            }
            .menu-icon.subscriptions {
                background-image: url('../../assets/images/ehpad/icon-subscriptions.svg');
            }
            .menu-icon.cart {
                background-image: url('../../assets/images/ehpad/icon-shopping-cart.svg');
            }
            .menu-icon.euro {
                background-image: url('../../assets/images/ehpad/icon-euro.svg');
            }
            .menu-icon.text {
                background-image: url('../../assets/images/ehpad/icon-file-text.svg');
            }
            .menu-icon.suggestions {
                background-image: url('../../assets/images/ehpad/icon-suggestions.svg');
            }
            .menu-chip { // TODO: Refacto this
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                padding-right: 1px;
                border-radius: 50%;
                background: white;
                font-weight: bold;
                font-size: 12px;
                color: var(--v-error-base);
                line-height: 1;
                border: 2px solid;
            }
        }
        ul {
            li {
                margin: 0 5px 0 5px;
                display: inline-block;
                border-radius: 4px;
                padding: 10px 15px;

                &.selected {
                    box-shadow: 0px 0px 10px 1px rgba(var(--vs-primary), 0.7);
                    background: linear-gradient(118deg, rgba(var(--vs-primary), 1), rgba(var(--vs-primary), 0.7));
                    a {
                        color: #fff
                    }
                    .menu-text {
                        color: white;
                    }
                    .menu-icon.user {
                        background-image: url('../../assets/images/ehpad/icon-user-selected.svg') !important;
                    }
                    .menu-icon.store {
                        background-image: url('../../assets/images/ehpad/icon-store-selected.svg') !important;
                    }
                    .menu-icon.recurence {
                        background-image: url('../../assets/images/ehpad/icon-recurence-selected.svg') !important;
                    }
                    .menu-icon.orders {
                        background-image: url('../../assets/images/ehpad/icon-orders-selected.svg') !important;
                    }
                    .menu-icon.subscriptions {
                        background-image: url('../../assets/images/ehpad/icon-subscriptions-selected.svg') !important;
                    }
                    .menu-icon.cart {
                        background-image: url('../../assets/images/ehpad/icon-shopping-cart-selected.svg') !important;
                    }
                    .menu-icon.euro {
                        background-image: url('../../assets/images/ehpad/icon-euro-selected.svg') !important;
                    }
                    .menu-icon.text {
                        background-image: url('../../assets/images/ehpad/icon-file-text-selected.svg') !important;
                    }
                    .menu-icon.suggestions {
                        background-image: url('../../assets/images/ehpad/icon-suggestions-selected.svg') !important;
                    }
                }
                a {
                    color: #636363;
                    cursor: pointer;
                }
            }

        }
    }
    .tabs {
        display: flex;
        border-radius: 0px 0px 8px 8px;
        background: white;

        .tab-item {
            min-width: 217px;
            padding: 30px 0px 22px 0px;
            border-bottom: 3px solid white;
            text-align: center;
            cursor: pointer;

            a {
                color: #2C2C2C;
            }
        }
        .tab-item:first-child {
            border-radius: 0px 0px 0px 8px;
        }
        .tab-item-selected {
            border-bottom: 3px solid rgba(var(--vs-primary), 1);

            a {
                font-weight: 600;
                color: rgba(var(--vs-primary), 1);
            }
        }
    }

}
</style>
