























































import Vue from 'vue'

import Routes from "@/constants/routes"

import { HSpeedDial, HIcon, HBtn }  from '@happytal/bo-ui-library'

export default Vue.extend({
    components: {
        HSpeedDial,
        HIcon,
        HBtn
    },

    props: {
        promotionalType: {
            type: String,
            required: true,
            validator: val => ['punctual','recurring'].includes(val)
        }
    },

    data() {
        return {
            popoverWidth: 'x-small',
            displayContent: true
        }
    },

    computed: {
        canDisplayCatalogBtn(): boolean {
            return this.$route.name !== Routes.ResidentCatalog && this.$route.name !== Routes.CatalogService
        }
    },

    methods: {
        displayLegalContent(): void {
            this.displayContent = false
            this.popoverWidth = 'medium'
        },
        onCatalogBtnClick(): void {
            const residentId = this.$route.params.resident_id
            if (residentId) {
                this.$router.push({
                    name: Routes.ResidentCatalog,
                    params: {
                        resident_id: residentId
                    }
                })
            }
        },
        resetToInitialState(): void {
            this.displayContent = true
            this.popoverWidth = 'x-small'
        },
        onToggleSpeedDial(val: boolean): void {
            if (!val) this.resetToInitialState()
        }
    },
})
