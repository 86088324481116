export default {
    computed: {
        $_canUserViewPromotional(): boolean {
            return this.$aclChecker.some(this.$acl, ['isFamily', 'isTutor'])
        },
        $_resident(): Record<any, any> | null {
            const residentId = this.$route.params.resident_id || ''
            return residentId ? this.$_getResidentById(residentId) : null
        },
        $_residentPromotionalElligibleType(): ('punctual' | 'recurring' | '') {
            if (this.$_resident) {
                if (this.$_resident.isElligiblePunctual) return 'punctual'
                if (this.$_resident.isElligibleRecurring) return 'recurring'
            }

            return ''
        }
    },
    methods: {
        $_getResidentById(residentId: string): Record<any, any> | undefined {
            return this.$store.getters['dataResidents/getResidentById'](residentId)
        },
    }
} as Record<any, any>